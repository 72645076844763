import React from 'react'
import { Image, Link as RebassLink } from 'rebass/styled-components'

import ArrowLeftDark from '../images/arrow-left-dark.svg'

const ReferenceBack = () => {
  const handleBack = (e) => {
    e.preventDefault()

    if (typeof window !== 'undefined') {
      if (window.history) {
        window.history.back(-1)
      }
    }
  }

  return (
    <RebassLink
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        mb: 4,
        color: 'primary',
        cursor: 'pointer',
        textDecoration: 'none',
        transition: 'all 200ms ease-out',
        '& img': {
          transition: 'all 200ms ease-out',
        },
        '&:hover': {
          // color: 'secondary',
          '& img': {
            transform: 'translateX(-0.5rem)',
          },
        },
      }}
      onClick={handleBack}
      href="/reference/"
    >
      <Image
        sx={{ width: '2rem', height: '2rem', mr: 3 }}
        src={ArrowLeftDark}
        alt=""
        aria-hidden
      />
      Zpět do realizovaných investic
    </RebassLink>
  )
}

export default ReferenceBack
