/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Card, Heading, Link, Box, Text } from 'rebass/styled-components'

import IconArrowDown from '../images/arrow-down.svg'

const getFilePublicURL = (files, filename) => {
  if (!Array.isArray(files)) {
    return false
  }

  const filteredFiles = files.filter((file) => file.relativePath === filename)

  if (filteredFiles.length > 0) {
    return filteredFiles[0].publicURL
  }

  return false
}

const Documents = ({ title }) => {
  const documents = [
    {
      name: 'Unijní prospekt 02',
      filename: 'acrebond-unijni-prospekt-2022-02-12.pdf',
    },
    {
      name: 'Unijní prospekt 01',
      filename: 'acrebond-unijni-prospekt.pdf',
    },
    {
      name: 'Rozhodnutí NPM 2022',
      filename: 'acrebond-rozhodnuti-NPM-priloha_1002053831.pdf',
    },
    {
      name: 'Rozhodnutí NPM',
      filename: 'acrebond-rozhodnuti-NPM.pdf',
    },
    {
      name: 'Business plán 2021-2026',
      filename: 'acrebond-brozura.pdf',
    },
    {
      name: 'Zpráva nezávislého auditora, 31.10.2021',
      filename: 'zprava-nezavisleho-auditora_2021-10-31.pdf',
    },
    {
      name: 'Zpráva nezávislého auditora, 31.12.2020',
      filename: 'zprava-nezavisleho-auditora_2020-12-31.pdf',
    },
    {
      name: 'Zpráva nezávislého auditora, 24.4.2020',
      filename: 'rozvaha.pdf',
    },
    {
      name: 'Vzor dluhopisu Acrebond',
      filename: 'vzor-dluhopisu-acrebond.jpg',
    },
    {
      name: 'Výroční zpráva',
      filename: null,
    },
  ]
  const data = useStaticQuery(graphql`
    {
      files: allFile(filter: { extension: { in: ["pdf", "jpg"] } }) {
        edges {
          node {
            publicURL
            relativePath
          }
        }
      }
    }
  `)
  const files = data.files.edges.map((edge) => edge.node)

  const gaTrackDownload = ({ label }) => {
    if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {
      window.gtag('event', 'click', { category: 'Tlačítko stáhnout', label })
    }
  }

  return (
    <>
      {title && (
        <Heading as="h2" sx={{ mb: 4, fontSize: 5 }}>
          {title}
        </Heading>
      )}

      <Box
        sx={{
          display: 'grid',
          gridAutoFlow: ['row', 'row', 'row'],
          gridTemplateColumns: ['auto', 'repeat(auto-fill, minmax(31%, 1fr))'],
          gap: 4,
        }}
      >
        {documents &&
          documents.length > 0 &&
          documents.map((document) => {
            const publicURL = getFilePublicURL(files, document.filename)

            return (
              <Card key={document.filename}>
                <Heading as="h3" variant="downloadHeading">
                  {document.name}
                </Heading>

                {publicURL && (
                  <Link
                    variant="linkButtonSecondary"
                    href={publicURL}
                    target="_blank"
                    onClick={() => gaTrackDownload({ label: document.name })}
                  >
                    Stáhnout
                    <img src={IconArrowDown} alt="" aria-hidden="true" />
                  </Link>
                )}

                {!publicURL && (
                  <Text sx={{ fontSize: 1, color: 'gray', lineHeight: '1.5' }}>
                    Dokument není v tuto chvíli dostupný
                  </Text>
                )}
              </Card>
            )
          })}
      </Box>
    </>
  )
}

Documents.propTypes = {
  title: PropTypes.string,
}

Documents.defaultProps = {
  title: null,
}

export default Documents
