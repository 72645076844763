import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Element } from 'react-scroll'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import IntroSection from '../components/sections/intro'
import ParametersSection from '../components/sections/parameters'
import UsageSection from '../components/sections/usage'
// import WhySection from '../components/sections/why'
import Newsletter from '../components/sections/newsletter'
import CalculatorSection from '../components/sections/calculator'
import ReferencesSection from '../components/sections/references'
import ContactSection from '../components/sections/contact'

const CustomReactScrollElement = ({ name, ...rest }) => {
  return <Element name={name} id={name} {...rest} />
}

const IndexPage = ({ data }) => {
  const { hero } = data
  const [investValue, setInvestValue] = useState(0)

  return (
    <Layout hidingHeader>
      <SEO
        title="Investice do nemovitostních dluhopisů"
        description="Investice do nemovitostí skrze firemní dluhopisy Acrebond."
      />

      <Hero
        image={hero.childImageSharp.gatsbyImageData}
        title="Stabilní investice,<br>které budete rozumět"
      />

      <CustomReactScrollElement name="investice-do-nemovitosti">
        <IntroSection />
      </CustomReactScrollElement>

      <ParametersSection investValue={investValue} />

      <CustomReactScrollElement name="obchodni-strategie">
        <UsageSection />
      </CustomReactScrollElement>

      <Newsletter />

      {/* <CustomReactScrollElement name="proc-investovat">
        <WhySection image={homeBg.childImageSharp.gatsbyImageData} />
      </CustomReactScrollElement> */}

      <CustomReactScrollElement name="kalkulacka">
        <CalculatorSection onRangeChange={(value) => setInvestValue(value)} />
      </CustomReactScrollElement>

      <ReferencesSection />

      <CustomReactScrollElement name="kontakt" id="kontakt">
        <ContactSection investValue={investValue} />
      </CustomReactScrollElement>
    </Layout>
  )
}

CustomReactScrollElement.propTypes = {
  name: PropTypes.string.isRequired,
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    hero: PropTypes.shape({
      childImageSharp: PropTypes.object.isRequired,
    }),
    homeBg: PropTypes.shape({
      childImageSharp: PropTypes.object.isRequired,
    }),
    referenceImages: PropTypes.array,
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
