import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button } from 'rebass/styled-components'

const Hamburger = ({ isOpen }) => {
  return (
    <Box
      sx={{
        width: '2rem',
        height: '2px',
        position: 'relativee',
        bg: isOpen ? 'transparent' : 'white',
        transition: 'all 200ms ease-out',
        transform: 'translateY(-0.5rem)',
        '&:after, &:before': {
          content: '""',
          display: 'block',
          position: 'relative',
          width: '2rem',
          height: '2px',
          bg: 'white',
          transition: 'all 200ms ease-out',
        },
        '&:after': {
          top: isOpen ? '6px' : '6px',
          width: isOpen ? '2rem' : '1.5rem',
          transform: isOpen ? 'rotate(-45deg)' : 'none',
        },
        '&:before': {
          top: isOpen ? '8px' : '16px',
          width: isOpen ? '2rem' : '1.75rem',
          transform: isOpen ? 'rotate(45deg)' : 'none',
        },
      }}
    />
  )
}

const MenuToggle = ({ isOpen, onClick }) => {
  return (
    <Button
      type="button"
      variant="menuToggle"
      title={isOpen ? 'Zavřít menu' : 'Otevřít menu'}
      onClick={onClick}
    >
      <Box sx={{ mr: 3 }}>Menu</Box>
      <Hamburger isOpen={isOpen} />
    </Button>
  )
}

Hamburger.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}

MenuToggle.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default MenuToggle
