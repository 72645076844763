import { rgba } from 'polished'

const colors = {
  primary: '#222222',
  secondary: '#FF8300',
  bodyText: '#52473C',
  lightSecondary: '#FFF5EB',
  line: '#CACBD8',
  lineColor: '#DAD3CB',
  rangeSliderThumbBorder: '#975208',
  inputBg: '#ECECF0',
}

const light = {
  colors,
  breakpoints: ['40em', '64em', '75em', '90rem'],
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fontSizes: [0, 14, 16, 18, 21, 24, 28, 32, 36, 42, 48, 64],
  fontWeights: {
    heading: 700,
    bold: 600,
    body: 400,
  },
  fonts: {
    body:
      'neue-haas-unica, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  lineHeights: {
    heading: 1.25,
    body: 1.75,
  },
  shadows: {
    card: `0px 8px 32px ${rgba(colors.primary, 0.1)}`,
  },
  text: {
    body: {
      color: 'bodyText',
      fontSize: 3,
      lineHeight: 1.75,
      '& p': {
        mb: 3,
      },
      '& h2': {
        mt: 5,
        fontSize: 4,
        fontWeight: 'bold',
      },
      a: {
        color: 'secondary',
      },
    },
    heading: {
      color: 'primary',
      lineHeight: 1.25,
    },
    siteTitle: {
      variant: 'text.heading',
      color: 'white',
      fontSize: 9,
      fontWeight: 'bold',
    },
    heroTitle: {
      mb: 1,
      color: 'white',
      fontSize: [6, 6, 10, 11],
    },
    heroSubtitle: {
      color: 'primary',
      fontSize: [5, 6, 7],
      fontWeight: 'heading',
    },
    sectionTitle: {
      color: 'primary',
      fontSize: [6, 7, 8],
    },
    motto: {
      variant: 'text.heading',
      color: 'secondary',
      fontSize: 8,
    },
    cardHeading: {
      variant: 'text.heading',
      mb: 3,
      position: 'relative',
      fontSize: 6,
      '& span': {
        pr: 3,
        position: 'relative',
        bg: 'white',
        zIndex: 1,
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        left: '0',
        top: '50%',
        width: '100%',
        height: '1px',
        bg: 'line',
      },
    },
    downloadHeading: {
      variant: 'text.heading',
      mb: 3,
      fontSize: 2,
      fontWeight: 'bold',
    },
    parameterTitle: {
      color: 'bodyText',
      fontSize: 3,
      fontWeight: 400,
    },
    parameterValue: {
      color: 'primary',
      fontSize: 5,
      fontWeight: 'bold',
    },
    benefitTitle: {
      fontSize: 5,
      fontWeight: 'bold',
      lineHeight: 1,
    },
    benefitValue: {
      color: 'primary',
      fontSize: 4,
      fontWeight: 400,
      lineHeight: 1.25,
    },
  },
  buttons: {
    secondary: {
      display: 'inline-block',
      px: 4,
      py: 3,
      borderRadius: 0,
      bg: 'secondary',
      color: 'primary',
      cursor: 'pointer',
      fontFamily: 'body',
      fontSize: 3,
      boxShadow: 'card',
      outline: 'none',
      transition: 'all 200ms ease-out',
      '&:hover': {
        bg: 'primary',
        color: 'white',
      },
    },
    menuToggle: {
      display: 'flex',
      // gridAutoFlow: 'column',
      alignItems: 'center',
      // gap: 3,
      py: '19px',
      px: 3,
      bg: 'primary',
      borderRadius: '0',
      color: 'white',
      fontSize: 1,
      fontWeight: 'bold',
      lineHeight: 1,
      outline: 'none',
      textDecoration: 'none',
      textTransform: 'uppercase',
      cursor: 'pointer',
      transition: 'all 200ms ease-out',
      '&:hover': {
        bg: '#000000',
      },
    },
  },
  variants: {
    hr: {
      my: 4,
      height: '1px',
      bg: 'line',
      border: 'none',
    },
    backtoHomepage: {
      variant: 'buttons.menuToggle',
    },
    container: {
      mx: 'auto',
      px: 3,
    },
    card: {
      p: 4,
      bg: 'white',
      boxShadow: 'card',
    },
    cardTopBorder: {
      variant: 'variants.card',
      p: 4,
      borderTop: '2px solid',
      borderTopColor: 'secondary',
    },
    footer: {
      py: 4,
      bg: 'primary',
      color: 'white',
    },
    hero: {
      body: {
        display: 'grid',
        gridAutoFlow: ['row', 'row', 'column'],
        gridTemplateColumns: ['auto', 'auto', '1fr 1fr'],
      },
    },
    linkButton: {
      textAlign: 'center',
      textDecoration: 'none',
      transition: 'all 200ms ease-out',
      p: '0.7rem 2rem 0.8rem 2rem',
      fontSize: 5,
    },
    linkButtonPrimary: {
      variant: 'variants.linkButton',
      display: 'inline-block',
      bg: 'secondary',
      color: 'primary',
      fontWeight: 'bold',
      lineHeight: '2',
      '&:hover, &:focus': {
        bg: 'primary',
        color: 'white',
      },
    },
    linkButtonSecondary: {
      variant: 'variants.linkButton',
      display: 'inline-grid',
      gap: 2,
      gridAutoFlow: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'primary',
      border: '1px solid',
      borderColor: 'secondary',
      '&:hover, &:focus': {
        boxShadow: 'card',
        bg: 'primary',
        color: 'white',
      },
      '& img': {
        lineHeight: 0,
      },
    },
    contactLink: {
      color: 'white',
      fontSize: 3,
      letterSpacing: '1px',
      textDecoration: 'none',
      display: 'grid',
      gridAutoFlow: 'column',
      justifyContent: 'start',
      alignContent: 'center',
      alignItems: 'center',
      gap: 3,
    },
    navLink: {
      py: 2,
      display: 'block',
      color: 'white',
      cursor: 'pointer',
      fontSize: 3,
      fontWeight: 'bold',
      letterSpacing: '-1px',
      lineHeight: '1.25',
      textDecoration: 'none',
      textTransform: 'uppercase',
      transition: 'all 200ms ease-out',
      '&:hover': {
        color: 'secondary',
      },
    },
    navLinkActive: {
      variant: 'variants.navLink',
      color: 'secondary',
    },
    // Submenu
    submenu: {
      pl: 3,
      pb: 3,
    },
    submenuNavLink: {
      variant: 'variants.navLink',
      color: 'secondary',
      fontSize: 2,
      '&:hover, &:focus': {
        color: 'lightSecondary',
      },
    },
    submenuNavLinkActive: {
      variant: 'variants.submenuNavLink',
      color: 'lightSecondary',
    },
    // Table row variants
    expense: {
      bg: 'primary',
      color: '#fff',
      '&:hover': {
        bg: 'primary',
        color: '#fff',
      },
    },
    revenue: {
      bg: 'primary',
      color: '#fff',
      '&:hover': {
        bg: 'primary',
        color: '#fff',
      },
    },
  },
  forms: {
    input: {
      px: 3,
      py: 3,
      bg: 'inputBg',
      border: 'none',
      fontFamily: 'body',
      color: 'primary',
      lineHeight: 1.75,
      outline: 'none',
    },
    textarea: {
      variant: 'forms.input',
    },
    label: {},
    radio: {},
    checkbox: {},
  },
  style: {
    root: {
      fontVariantNumeric: 'lining-nums',
    },
  },
}

export default light
