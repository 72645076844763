import React from 'react'
import { Link } from 'gatsby'
import {
  Box,
  Heading,
  Link as RebassLink,
} from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Container from '../components/container'

const NotFoundPage = () => (
  <Layout hideMenuToggle showSecondaryLogo>
    <SEO title="404: Nenalezeno" />

    <Box sx={{ py: 6 }}>
      <Container>
        <Heading as="h1" sx={{ mb: 5, color: 'primary', fontSize: 6 }}>
          Stránka neexistuje
        </Heading>

        <Heading as="h2" sx={{ mb: 4 }}>
          Omlouváme se, ale na tomto odkaze nemáme žádnou stránku.
        </Heading>

        <RebassLink as={Link} to="/" sx={{ color: 'primary' }}>
          Návrat na hlavní stranu
        </RebassLink>
      </Container>
    </Box>
  </Layout>
)

export default NotFoundPage
