import React from 'react'

import ArrowLeft from '../images/arrow-left.svg'

/* eslint-disable import/prefer-default-export */
export const slickSettings = {
  accessibility: true,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 4000,
  centerMode: true,
  centerPadding: 0,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: (
    <button type="button" style={{ cursor: 'pointer' }}>
      <img style={{ width: '2rem' }} src={ArrowLeft} alt="" />
    </button>
  ),
  nextArrow: (
    <button type="button" style={{ cursor: 'pointer' }}>
      <img
        style={{ width: '2rem', transform: 'rotate(180deg)' }}
        src={ArrowLeft}
        alt=""
      />
    </button>
  ),
}
