import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Flex, Text } from 'rebass/styled-components'

import formatCurrency from '../utils/formatCurrency'

const Calculator = ({ step, onValueChange }) => {
  const defaultRange = step * 4
  const [range, setRange] = useState(defaultRange)
  const years = 5
  const pa = 7.0
  const min = step
  const maxPieces = 200
  const max = maxPieces * step
  let minFormated = formatCurrency(min)
  let maxFormated = formatCurrency(max)
  let rangeFormated = formatCurrency(range)
  let gainYearly = (range / 100) * pa
  let gainYearlyFormatted = formatCurrency(gainYearly)
  let gainTotal = gainYearly * years
  let gainTotalFormatted = formatCurrency(gainTotal)

  useEffect(() => {
    minFormated = formatCurrency(min)
    maxFormated = formatCurrency(max)
    rangeFormated = formatCurrency(range)
    gainYearly = (range / 100) * pa
    gainYearlyFormatted = formatCurrency(gainYearly)
    gainTotal = gainYearly * years
    gainTotalFormatted = formatCurrency(gainTotal)

    onValueChange({
      range,
      gainTotal,
    })
  }, [range])

  const handleRangeChange = (e) => {
    const rangeValue = e.target.value
    setRange(rangeValue)
  }

  const handleRangeMouseUp = (e) => {
    console.log(e.target.value)
  }

  return (
    <Box>
      <Box sx={{ position: 'relative', width: '100%', mb: 3 }}>
        <Text variant="body">Investice</Text>
        <Text sx={{ fontSize: 5, color: 'primary', fontWeight: 700 }}>
          {rangeFormated}
        </Text>
      </Box>

      <Box sx={{ width: '100%', mx: 'auto', mb: 1 }}>
        <input
          type="range"
          value={range}
          min={min}
          step={step}
          max={max}
          onChange={handleRangeChange}
          onMouseUp={handleRangeMouseUp}
          style={{ width: '100%', backgroundColor: 'transparent' }}
        />
      </Box>

      <Flex
        sx={{
          width: '100%',
          mx: 'auto',
          justifyContent: 'space-between',
        }}
      >
        <Text variant="body">{minFormated}</Text>
        <Text variant="body">{maxFormated}</Text>
      </Flex>

      <Box sx={{ display: 'none', gap: 2, opacity: 0 }}>
        <Box>
          Investice:&nbsp;
          <input type="text" value={rangeFormated} readOnly />
        </Box>
        <Box>
          Úrokový výnos za rok:&nbsp;
          <input type="text" value={gainYearlyFormatted} readOnly />
        </Box>
        <Box>
          Úrokový výnos při držbě do splatnosti:&nbsp;
          <input type="text" value={gainTotalFormatted} readOnly />
        </Box>
      </Box>
    </Box>
  )
}

Calculator.propTypes = {
  step: PropTypes.number.isRequired,
  onValueChange: PropTypes.func,
}

Calculator.defaultProps = {
  onValueChange: () => {},
}

export default Calculator
