import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box } from 'rebass/styled-components'

const ReferenceImage = ({ image, title }) => {
  const hasBeforeImage = Object.prototype.hasOwnProperty.call(image, 'before')
  const hasAfterImage = Object.prototype.hasOwnProperty.call(image, 'after')

  return (
    <Box sx={{ position: 'relative' }}>
      {hasAfterImage && image.after && (
        <Box>
          <GatsbyImage
            style={{ width: '100%' }}
            image={image.after.node.childImageSharp.gatsbyImageData}
            alt={`Po: ${title}`}
          />
        </Box>
      )}

      {hasBeforeImage && image.before && (
        <Box
          sx={{
            position: 'absolute',
            right: [2, 2, 3],
            top: [2, 2, 3],
            width: '30%',
            zIndex: 1,
            boxShadow: '0 0.5rem 2rem rgba(0, 0, 0, 0.4)',
          }}
        >
          <GatsbyImage
            image={image.before.node.childImageSharp.gatsbyImageData}
            alt={`Před: ${title}`}
          />
        </Box>
      )}
    </Box>
  )
}

ReferenceImage.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string,
}

ReferenceImage.defaultProps = {
  title: null,
}

export default ReferenceImage
