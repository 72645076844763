import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'rebass/styled-components'

const Section = ({ children, py = 5, sx, ...rest }) => (
  <Box
    variant="section"
    sx={{
      py,
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
)

Section.propTypes = {
  children: PropTypes.node.isRequired,
  py: PropTypes.number,
  sx: PropTypes.object,
}

Section.defaultProps = {
  py: 5,
  sx: {},
}

export default Section
