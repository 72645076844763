/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import { Heading, Text, Box } from 'rebass/styled-components'

import Section from '../section'
import Container from '../container'

const IntroSection = () => {
  const data = useStaticQuery(graphql`
    {
      intro1: markdownRemark(frontmatter: { slug: { eq: "intro1" } }) {
        html
      }
      intro2: markdownRemark(frontmatter: { slug: { eq: "intro2" } }) {
        frontmatter {
          title
        }
        html
      }
      intro3: markdownRemark(frontmatter: { slug: { eq: "intro3" } }) {
        html
      }
      introBg: file(relativePath: { eq: "intro-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      dluhopisVzor: file(relativePath: { eq: "dluhopis-vzor.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Section sx={{ pt: 0 }}>
      <Box
        as={BgImage}
        image={data.introBg.childImageSharp.gatsbyImageData}
        backgroundColor="#222222"
        sx={{
          width: '100%',
          height: '100%',
          py: 5,
          backgroundPosition: '50% 0%',
          backgroundSize: 'cover',
          maxWidth: ['100%', '100%', '100%', '70%'],
          p: 0,
          mt: [0, 0, 0, -6],
          mb: 4,
          zIndex: 2,
          position: 'relative',
          '&:after, &:before': { mixBlendMode: 'multiply', opacity: 0.2 },
        }}
      >
        <Text
          sx={{
            width: ['auto', 'auto', 'auto', '100%'],
            py: 5,
            px: 5,
            pl: [3, 3, 5, 'calc((100% - 60rem / 2) - 26.5%)'],
            color: 'white',
            fontSize: 3,
            lineHeight: '1.75',
          }}
          dangerouslySetInnerHTML={{ __html: data.intro1.html }}
        />
      </Box>

      <Container>
        <Box
          sx={{
            display: 'grid',
            gap: 4,
          }}
        >
          <Heading as="h2" variant="sectionTitle">
            {data.intro2.frontmatter.title}
          </Heading>
          <Text
            variant="body"
            dangerouslySetInnerHTML={{ __html: data.intro2.html }}
          />

          <Box
            sx={{
              display: 'grid',
              gridAutoFlow: ['row', 'column'],
              gridTemplateColumns: ['1fr', '1.35fr 1fr'],
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Text
              variant="body"
              dangerouslySetInnerHTML={{ __html: data.intro3.html }}
            />

            <GatsbyImage
              image={data.dluhopisVzor.childImageSharp.gatsbyImageData}
              alt="Dluhopis Acrebond"
              style={{ width: '100%', maxWidth: '640px', alignSelf: 'center' }}
            />
          </Box>
        </Box>
      </Container>
    </Section>
  )
}

export default IntroSection
