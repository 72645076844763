import React from 'react'
import PropTypes from 'prop-types'
import { Heading, Text, Box, Card, Link, Image } from 'rebass/styled-components'
import { Link as ScrollLink } from 'react-scroll'

import Section from '../section'
import Container from '../container'
import CalculatorIcon from '../../images/calculator-icon.svg'
import CalculatorBlackIcon from '../../images/calculator-icon-black.svg'
import { trackConversion } from '../../utils/trackConversion'

const Parameter = ({ title, value }) => {
  return (
    <Box
      sx={{
        px: 4,
        py: 3,
      }}
    >
      <Heading as="h3" variant="parameterTitle">
        {title}
      </Heading>

      <Text as="p" variant="parameterValue">
        {value}
      </Text>

      <Box sx={{ width: '100%', height: '1px', bg: 'secondary', mt: 3 }} />
    </Box>
  )
}

const Parameters = ({ children, investValue = 0, ...rest }) => {
  return (
    <Card
      variant="cardTopBorder"
      sx={{
        py: 3,
        px: 2,
        pb: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          mb: 3,
          display: 'grid',
          width: '100%',
          gridTemplateColumns: [
            '1fr',
            'repeat(auto-fill, minmax(50%, 1fr))',
            'repeat(auto-fill, minmax(33%, 1fr))',
          ],
        }}
        {...rest}
      >
        {children}
      </Box>

      <Box sx={{ display: 'grid', gridAutoFlow: ['row', 'column'], gap: [3, 4] }}>
        <Link
          variant="linkButtonSecondary"
          as={ScrollLink}
          to="kalkulacka"
          smooth="easeInOutQuint"
          duration={500}
          sx={{
            p: '0.7rem 2rem 0.8rem 2rem',
            fontSize: 5,
            display: 'grid',
            gridAutoFlow: 'column',
            gap: 3,
            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'center',
            '& .iconWhite': {
              display: 'none',
            },
            '&:hover .iconBlack': {
              display: 'none',
            },
            '&:hover .iconWhite': {
              display: 'block',
            },
          }}
        >
          <Image
            className="iconBlack"
            src={CalculatorBlackIcon}
            alt=""
            aria-hidden="true"
            sx={{
              width: '1.5rem',
              height: '1.5rem',
            }}
          />
          <Image
            className="iconWhite"
            src={CalculatorIcon}
            alt=""
            aria-hidden="true"
            sx={{
              width: '1.5rem',
              height: '1.5rem',
            }}
          />
          Kalkulačka výnosů
        </Link>

        <Link
          variant="linkButtonPrimary"
          href="https://dluhopisy.acrebond.cz"
          target="_blank"
          sx={{
            p: '0.7rem 2rem 0.8rem 2rem',
            fontSize: 5,
            display: 'grid',
            gridAutoFlow: 'column',
            gap: 3,
            cursor: 'pointer',
          }}
          onClick={() => trackConversion(investValue)}
        >
          Chci investovat
        </Link>
      </Box>
    </Card>
  )
}

const ParametersSection = ({ investValue }) => {
  return (
    <Section
      sx={{
        py: 0,
        position: 'relative',
        bg: 'lightSecondary',
        '&:after': {
          content: '""',
          width: '100%',
          height: '100%',
          position: 'absolute',
          clipPath: 'polygon(100% 0, 100% 45%, 66% 65%, 33% 70%, 0 80%, 0 0)',
          bg: 'white',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      }}
    >
      <Box sx={{ position: 'relative', zIndex: 1 }}>
        <Container>
          <Parameters investValue={investValue}>
            <Parameter title="Název emise" value="Acrebond 02 / 7,0 %" />
            <Parameter
              title="Nominální hodnota dluhopisu"
              value="25&nbsp;000 Kč"
            />
            <Parameter title="Počet kusů" value="8 000" />
            <Parameter
              title="Celkový objem emise"
              value="200&nbsp;000&nbsp;000 Kč"
            />
            <Parameter title="Úroková sazba" value="7,0 % p.a." />
            <Parameter title="Výplata úroků" value="pololetní" />
            <Parameter title="Datum emise" value="14. 2. 2022" />
            <Parameter title="Datum splatnosti" value="14. 2. 2027" />
            <Parameter title="Prospekt" value="Schválen ČNB" />
          </Parameters>
        </Container>
      </Box>
    </Section>
  )
}

Parameter.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

Parameters.propTypes = {
  children: PropTypes.node.isRequired,
  investValue: PropTypes.number,
}

ParametersSection.propTypes = {
  investValue: PropTypes.number.isRequired,
}

export default ParametersSection
