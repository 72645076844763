import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { Heading, Box } from 'rebass/styled-components'
import { BgImage } from 'gbimage-bridge'

import Section from '../section'
import Container from '../container'
import ContactInfo from '../contact-info'

const StyledBackgroundImage = ({ children, ...rest }) => (
  <Box
    sx={{
      py: 5,
      backgroundPosition: '50% 0%',
      backgroundSize: 'cover',
      '&:after, &:before': { mixBlendMode: 'multiply', opacity: 0.2 },
    }}
    backgroundColor="#222222"
    as={BgImage}
    {...rest}
  >
    {children}
  </Box>
)

const ContactSection = ({ investValue = 0, hideAddress = false }) => {
  const { contactUs } = useStaticQuery(graphql`
    {
      contactUs: file(relativePath: { eq: "contact-us-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Section sx={{ py: 0, bg: 'primary' }}>
      <StyledBackgroundImage image={contactUs.childImageSharp.gatsbyImageData} Tag="div">
        <Container>
          <Box
            sx={{
              display: 'grid',
              gap: 4,
            }}
          >
            <Heading as="h2" variant="sectionTitle" sx={{ color: 'white' }}>
              Kontaktujte nás
            </Heading>

            <ContactInfo investValue={investValue} hideAddress={hideAddress} />
          </Box>
        </Container>
      </StyledBackgroundImage>
    </Section>
  )
}

StyledBackgroundImage.propTypes = {
  children: PropTypes.node.isRequired,
}

ContactSection.propTypes = {
  investValue: PropTypes.number,
  hideAddress: PropTypes.bool,
}

ContactSection.defaultProps = {
  investValue: 0,
  hideAddress: false,
}

export default ContactSection
