import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Reset } from 'styled-reset'
import { ThemeProvider } from 'styled-components'
import 'animate.css/animate.min.css'

import GlobalStyle from '../styles/globalStyle'
import light from '../themes/default'
import Header from './header'
import Footer from './footer'

const Layout = ({
  children,
  hideMenuToggle,
  showSecondaryLogo,
  hidingHeader,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={light}>
      <Helmet>
        <meta name="format-detection" content="telephone=no" />
        <link rel="stylesheet" href="https://use.typekit.net/fse8zzb.css" />
      </Helmet>
      <Reset />
      <GlobalStyle />

      <Header
        siteTitle={data.site.siteMetadata.title}
        hideMenuToggle={hideMenuToggle}
        showSecondaryLogo={showSecondaryLogo}
        hidingHeader={hidingHeader}
      />

      <main>{children}</main>

      <Footer siteTitle={data.site.siteMetadata.title} />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hideMenuToggle: PropTypes.bool,
  showSecondaryLogo: PropTypes.bool,
  hidingHeader: PropTypes.bool,
}

Layout.defaultProps = {
  hideMenuToggle: false,
  showSecondaryLogo: false,
  hidingHeader: false,
}

export default Layout
