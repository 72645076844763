import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Box, Heading, Text } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/section'
import Container from '../components/container'
import Documents from '../components/documents'

const AboutPage = ({ data }) => (
  <Layout hideMenuToggle showSecondaryLogo>
    <SEO
      title={data.companyInfo.frontmatter.title}
      description="Informace o společnosti Acrebond a.s."
    />

    <Section sx={{ pt: 6 }}>
      <Container>
        <Heading as="h1" sx={{ mb: 3, color: 'primary', fontSize: 8 }}>
          {data.companyInfo.frontmatter.title}
        </Heading>

        <Box
          sx={{
            display: 'grid',
            gap: 4,
            gridAutoFlow: ['row', 'column', 'column'],
            gridTemplateColumns: ['1fr', '1fr 1fr'],
          }}
        >
          <Text
            variant="body"
            sx={{ fontSize: 4 }}
            dangerouslySetInnerHTML={{ __html: data.companyAddress.html }}
          />

          <Text
            variant="body"
            sx={{ fontSize: 2 }}
            dangerouslySetInnerHTML={{ __html: data.companyInfo.html }}
          />
        </Box>
      </Container>
    </Section>

    <Section sx={{ bg: 'lightSecondary' }}>
      <Container>
        <Documents title="Dokumenty ke stažení" />
      </Container>
    </Section>
  </Layout>
)

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const pageQuery = graphql`
  {
    companyInfo: markdownRemark(frontmatter: { slug: { eq: "companyInfo" } }) {
      frontmatter {
        title
      }
      html
    }
    companyAddress: markdownRemark(
      frontmatter: { slug: { eq: "companyAddress" } }
    ) {
      frontmatter {
        title
      }
      html
    }
    aboutUs: markdownRemark(frontmatter: { slug: { eq: "about" } }) {
      frontmatter {
        title
      }
      html
    }
    brozura: file(relativePath: { eq: "brozura.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
