import React from 'react'
import PropTypes from 'prop-types'
import { Box, Flex, Heading, Link, Text } from 'rebass/styled-components'
import { BgImage } from 'gbimage-bridge'
import { Link as ScrollLink } from 'react-scroll'
import ScrollAnimation from 'react-animate-on-scroll'

import Container from './container'
import ArrowDown from '../images/la_long-arrow-alt-down-solid.svg'
import CalculatorIcon from '../images/calculator-icon.svg'
import GraphIcon from '../images/graph-icon.svg'
import { trackConversion } from '../utils/trackConversion'

const StyledBackgroundImage = ({ children, ...rest }) => (
  <Flex
    sx={{
      minHeight: '100vh',
      bg: '#FF8300',
      backgroundPosition: '50% 0%',
      justifyContent: 'center',
      alignItems: ['flex-end', 'center', 'center'],
      pt: [6, 4],
    }}
    as={BgImage}
    {...rest}
  >
    {children}
  </Flex>
)

const HeroBody = ({ children, sx = {}, ...rest }) => {
  return (
    <Box
      sx={{
        pb: 4,
        flexGrow: 1,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

const HeroTitle = ({ children, ...rest }) => (
  <Heading as="h1" variant="heroTitle" {...rest}>
    {children}
  </Heading>
)

const HeroSubtitle = ({ children }) => (
  <Text as="p" variant="heroSubtitle">
    {children}
  </Text>
)

const DecorationLine = () => (
  <Box
    sx={{
      width: '1px',
      height: '80%',
      bg: 'white',
      position: 'absolute',
      bottom: '0',
      right: '120px',
      zIndex: 0,
      display: ['none', 'none', 'block'],
    }}
  />
)

const ScrollPrompt = () => {
  return (
    <Flex
      sx={{
        height: '10rem',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        width: 'max-content',
      }}
    >
      <Text
        sx={{
          m: 0,
          p: 0,
          color: 'white',
          transform: 'rotate(-90deg) translateY(8px)',
          transformOrigin: '0 0',
          display: 'flex',
          width: 'max-content',
        }}
      >
        Skrolujte
      </Text>
      <img src={ArrowDown} alt="" aria-hidden="true" />
    </Flex>
  )
}

const ButtonGroup = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridAutoFlow: 'row',
        gap: [2, 3],
        position: ['relative', 'absolute'],
        bottom: ['auto', 4, 4, 6],
        right: ['auto', 4, 4, 2],
        zIndex: 1,
      }}
    >
      {children}
    </Box>
  )
}

const CalculatorButton = () => (
  <Box
    as={ScrollLink}
    sx={{
      display: 'grid',
      gridAutoFlow: 'column',
      gap: 3,
      alignItems: 'center',
      justifyContent: 'start',
      mt: [3, 0],
      p: [3, 4, 4],
      bg: 'primary',
      boxShadow: 'card',
      color: 'white',
      cursor: 'pointer',
      transition: 'all 200ms ease-out',
      '&:hover': {
        bg: '#000000',
      },
    }}
    smooth="easeInOutQuint"
    duration={500}
    to="kalkulacka"
  >
    <img src={CalculatorIcon} alt="" aria-hidden="true" />
    Kalkulačka výnosu z&nbsp;dluhopisu
  </Box>
)

const InvestButton = () => (
  <Link
    variant="linkButton"
    href="https://dluhopisy.acrebond.cz"
    target="_blank"
    sx={{
      display: 'grid',
      gridAutoFlow: 'column',
      gap: 3,
      alignItems: 'center',
      justifyContent: 'start',
      p: [3, 4, 4],
      bg: 'primary',
      color: 'white',
      fontSize: 2,
      fontWeight: 'normal',
      cursor: 'pointer',
      textAlign: 'left',
      transition: 'all 200ms ease-out',
      '&:hover': {
        bg: '#000000',
      },
    }}
    onClick={() => trackConversion()}
  >
    <img src={GraphIcon} alt="" aria-hidden="true" />
    Investovat do dluhopisu
  </Link>
)

const Hero = ({ image, title, subtitle }) => {
  return (
    <StyledBackgroundImage image={image} Tag="section">
      <HeroBody>
        <Container
          sx={{
            position: 'relative',
            maxWidth: ['100%', '100%', '70%'],
            width: '100%',
            margin: 0,
            paddingLeft: [3, 3, '20%'],
            paddingRight: '0',
          }}
        >
          <ScrollAnimation
            animateIn="animate__fadeInDown"
            duration={0.6}
            delay={0.3}
            animateOnce
          >
            <HeroTitle dangerouslySetInnerHTML={{ __html: title }} />
          </ScrollAnimation>

          {subtitle && (
            <ScrollAnimation
              animateIn="animate__fadeInDown"
              duration={0.6}
              delay={0.5}
              animateOnce
            >
              <HeroSubtitle>{subtitle}</HeroSubtitle>
            </ScrollAnimation>
          )}

          <ScrollAnimation
            animateIn="animate__fadeInDown"
            duration={0.6}
            delay={0.6}
            animateOnce
          >
            <ScrollLink
              to="investice-do-nemovitosti"
              smooth="easeInOutQuint"
              duration={500}
            >
              <ScrollPrompt />
            </ScrollLink>
          </ScrollAnimation>

          <Text
            sx={{
              position: 'absolute',
              bottom: 0,
              fontWeight: 'bold',
              color: 'rgba(255, 255, 255, 0.2)',
              fontSize: ['28rem', '36rem'],
              lineHeight: 0.82,
              left: ['2%', '40%', '60%'],
              letterSpacing: '-4rem',
            }}
          >
            7<Box sx={{ display: 'inline', fontSize: '70%' }}>%</Box>
          </Text>
        </Container>

        <DecorationLine />

        <ButtonGroup>
          <CalculatorButton />

          <InvestButton />
        </ButtonGroup>
      </HeroBody>
    </StyledBackgroundImage>
  )
}

StyledBackgroundImage.propTypes = {
  children: PropTypes.node.isRequired,
}

ButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
}

HeroBody.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
}

HeroTitle.propTypes = {
  children: PropTypes.node,
}

HeroSubtitle.propTypes = {
  children: PropTypes.node,
}

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  image: PropTypes.object.isRequired,
}

export default Hero
