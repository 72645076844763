import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text, Link } from 'rebass/styled-components'

import { trackConversion } from '../utils/trackConversion'
import IconPhone from '../images/phone.svg'
import IconEmail from '../images/email.svg'
import IconWebsite from '../images/website.svg'

const ContactInfo = ({ investValue, hideAddress }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        alignItems: ['start'],
        gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)'],
        gap: [3, 3, 0],
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gap: 0,
          lineHeight: '1.75',
        }}
      >
        <Link variant="contactLink" href="mailto:dluhopisy@acrebond.cz">
          <img src={IconEmail} alt="" aria-hidden="true" />
          dluhopisy@acrebond.cz
        </Link>

        <Link
          variant="contactLink"
          href="https://dluhopisy.acrebond.cz"
          target="_blank"
        >
          <img
            src={IconWebsite}
            alt=""
            aria-hidden="true"
            style={{ width: '1rem' }}
          />
          dluhopisy.acrebond.cz
        </Link>
      </Box>

      {!hideAddress && (
        <Box>
          <Text sx={{ mb: 3, color: 'white', fontSize: 3, lineHeight: '1.75' }}>
            <strong>Acrebond a.s.</strong>
            <br />
            Ve Smečkách 589/14
            <br />
            Nové Město Praha 1
            <br />
            110 00 Praha
          </Text>

          <Text sx={{ mb: 3, color: 'white', fontSize: 3, lineHeight: '1.75' }}>
            <strong>IČ:</strong>
            &nbsp;09111573
          </Text>
        </Box>
      )}

      <Box>
        <Text as="p">
          <Link
            href="https://dluhopisy.acrebond.cz"
            target="_blank"
            variant="linkButtonPrimary"
            title="dluhopisy.acrebond.cz"
            onClick={() => trackConversion(investValue)}
          >
            Chci investovat
          </Link>
        </Text>
      </Box>
    </Box>
  )
}

ContactInfo.propTypes = {
  investValue: PropTypes.number,
  hideAddress: PropTypes.bool,
}

ContactInfo.defaultProps = {
  investValue: 0,
  hideAddress: false,
}

export default ContactInfo
