import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import { Flex, Box, Link as RebassLink } from 'rebass/styled-components'
import { Link as ScrollLink } from 'react-scroll'

import LogoWhiteLine from '../images/logo-black-white-line.svg'
import LogoColorLine from '../images/logo-black-color-line.svg'
import MenuToggle from './menu-toggle'
import { useScrollPosition } from '../hooks/useScrollPosition'

const GatsbyNavLink = ({ children, ...rest }) => {
  return (
    <RebassLink as={Link} variant="navLink" {...rest}>
      {children}
    </RebassLink>
  )
}

const Submenu = ({ children }) => {
  return (
    <Box as="ul" variant="submenu">
      {children}
    </Box>
  )
}

const Header = ({
  siteTitle,
  hideMenuToggle,
  showSecondaryLogo,
  hidingHeader,
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isPageScrolled, setPageScrolled] = useState(false)
  const [currentSection, setCurrentSection] = useState(null)

  const [headerStyle, setHeaderStyle] = useState({
    transition: 'all 200ms ease-in',
  })

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (!hidingHeader) {
        setHeaderStyle({
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
        })
        return
      }

      const isVisible = currPos.y > prevPos.y || currPos.y >= 0
      const isScrolled = hidingHeader && currPos.y < -400

      const shouldBeStyle = {
        visibility: isVisible ? 'visible' : 'hidden',
        transition: `all 200ms ${isVisible ? 'ease-in' : 'ease-out'}`,
        transform: isVisible ? 'none' : 'translate(0, -100%)',
        backgroundColor: isScrolled
          ? 'rgba(255, 255, 255, 0.95)'
          : 'transparent',
        boxShadow: isScrolled ? '0 4px 32px rgba(0, 0, 0, 0.1)' : 'none',
      }

      if (JSON.stringify(shouldBeStyle) === JSON.stringify(headerStyle)) return

      setHeaderStyle(shouldBeStyle)
      setPageScrolled(isScrolled)
    },
    [headerStyle],
    false,
    false,
    100
  )

  const handleNavLinkClick = (sectionName) => {
    setCurrentSection(sectionName)
    setMenuOpen(false)
  }

  const NavLink = useCallback(
    ({ children, to, ...rest }) => {
      const isActive = currentSection === to
      const location = useLocation()
      const isHomepage = location.pathname === '/'

      return (
        <RebassLink
          variant={isActive ? 'submenuNavLinkActive' : 'submenuNavLink'}
          as={isHomepage ? ScrollLink : Link}
          smooth="easeInOutQuint"
          duration={500}
          to={isHomepage ? to : `/#${to}`}
          onClick={() => handleNavLinkClick(to)}
          {...rest}
        >
          {children}
        </RebassLink>
      )
    },
    [currentSection]
  )

  return (
    <Flex
      as="header"
      sx={{
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        zIndex: 100,
      }}
    >
      <Box
        sx={{
          justifyContent: 'space-between',
          py: 3,
          alignItems: 'center',
          width: '100%',
          display: 'grid',
          gridAutoFlow: 'column',
          gap: 3,
          ...headerStyle,
        }}
      >
        <Box sx={{ ml: [3, 4, 4], my: 3 }}>
          <Link to="/">
            <img
              src={
                isPageScrolled || showSecondaryLogo
                  ? LogoColorLine
                  : LogoWhiteLine
              }
              alt={siteTitle}
              style={{ maxWidth: '100%' }}
            />
          </Link>
        </Box>

        <MenuToggle
          isOpen={isMenuOpen}
          onClick={() => setMenuOpen(!isMenuOpen)}
        />
      </Box>

      <Box
        as="ul"
        sx={{
          position: 'fixed',
          p: [4, 4, 4],
          pr: [4, 4, 5],
          bg: 'primary',
          color: 'white',
          top: '15%',
          right: isMenuOpen ? 0 : '-140%',
          boxShadow: 'card',
          transition: 'all 250ms ease-in-out',
        }}
      >
        <li>
          <GatsbyNavLink to="/">Hlavní strana</GatsbyNavLink>

          <Submenu>
            <li>
              <NavLink to="investice-do-nemovitosti">
                Investice do nemovitostí
              </NavLink>
            </li>
            <li>
              <NavLink to="obchodni-strategie">Obchodní strategie</NavLink>
            </li>
            {/* <li>
              <NavLink to="proc-investovat">Proč investovat</NavLink>
            </li> */}
            <li>
              <NavLink to="kalkulacka">Kalkulačka výnosů</NavLink>
            </li>
          </Submenu>
        </li>
        <li>
          <GatsbyNavLink to="/pro-investory">Pro investory</GatsbyNavLink>
        </li>
        <li>
          <GatsbyNavLink to="/reference">
            Příklady realizovaných investic
          </GatsbyNavLink>
        </li>
        <li>
          <GatsbyNavLink to="/blog">Blog</GatsbyNavLink>
        </li>
        <li>
          <GatsbyNavLink to="/#kontakt">Kontakt</GatsbyNavLink>
        </li>
      </Box>
    </Flex>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  hideMenuToggle: PropTypes.bool,
  showSecondaryLogo: PropTypes.bool,
  hidingHeader: PropTypes.bool,
}

Header.defaultProps = {
  siteTitle: '',
  hideMenuToggle: false,
  showSecondaryLogo: false,
  hidingHeader: false,
}

GatsbyNavLink.propTypes = {
  children: PropTypes.node.isRequired,
}

Submenu.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Header
