import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Box, Link, Text } from 'rebass/styled-components'
import Slider from 'react-slick'

import ReferenceImage from './reference-image'
import { slickSettings } from '../utils/slickSettings'
import { getImages } from '../utils/getImages'

const Table = ({ children }) => (
  <Box as="table" sx={{ width: '100%', mb: 4, borderCollapse: '1' }}>
    {children}
  </Box>
)
const Row = ({ children, variant }) => (
  <Box
    as="tr"
    variant={variant}
    sx={{
      display: 'table-row',
      transition: 'all 200ms ease-out',
      '&:hover': {
        bg: '#f0f0f0',
        color: 'primary',
      },
    }}
  >
    {children}
  </Box>
)
const Cell = ({ children, sx = {} }) => (
  <Box
    as="td"
    sx={{
      display: 'table-cell',
      width: '50%',
      p: 2,
      // border: '1px solid #f0f0f0',
      border: 'none',
      ...sx,
    }}
  >
    {children}
  </Box>
)

const ReferenceDetail = ({ reference, images, files }) => {
  if (!reference) {
    return null
  }

  const sliderRef = useRef(null)
  const { title, description, imagePath, tables } = reference
  const referenceImages = getImages({
    referenceImages: reference.images,
    imagePath,
    images,
  })

  return (
    <Box>
      {description && (
        <Text variant="body" sx={{ mb: 4 }}>
          {description}
        </Text>
      )}

      {referenceImages && referenceImages.length > 0 && (
        <Box sx={{ mb: 5 }}>
          <Slider ref={sliderRef} {...slickSettings}>
            {referenceImages &&
              referenceImages.map((image) => {
                return <ReferenceImage image={image} title={title} />
              })}
          </Slider>
        </Box>
      )}

      {files && files.length > 0 && (
        <Box sx={{ mb: 4 }}>
          {files.map((file) => {
            const { publicURL, title: fileTitle } = file

            return (
              <Link
                variant="linkButtonPrimary"
                sx={{ px: 3, py: 2, fontSize: 3 }}
                href={publicURL}
                target="_blank"
              >
                {fileTitle}
              </Link>
            )
          })}
        </Box>
      )}

      {tables &&
        tables.length > 0 &&
        tables.map((table) => {
          const { rows } = table
          if (!rows) return null

          return (
            <Table>
              {rows.map((row) => {
                const { key, value, variant = null } = row

                return (
                  <Row variant={variant}>
                    <Cell>{key}</Cell>
                    <Cell sx={{ textAlign: 'right' }}>{value}</Cell>
                  </Row>
                )
              })}
            </Table>
          )
        })}
    </Box>
  )
}

ReferenceDetail.propTypes = {
  images: PropTypes.array,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      filename: PropTypes.string,
    })
  ),
  reference: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    imagePath: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        after: PropTypes.string,
        before: PropTypes.string,
      })
    ),
    tables: PropTypes.array,
  }),
}

export default ReferenceDetail
