import React from 'react'
import { Link } from '@reach/router'
import { Box, Image } from 'rebass/styled-components'

import ArrowLeftDark from '../images/arrow-left-dark.svg'

const BlogBack = () => {
  return (
    <Box
      as={Link}
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        mb: 4,
        color: 'primary',
        cursor: 'pointer',
        textDecoration: 'none',
        transition: 'all 200ms ease-out',
        '& img': {
          transition: 'all 200ms ease-out',
        },
        '&:hover': {
          '& img': {
            transform: 'translateX(-0.5rem)',
          },
        },
      }}
      to="/blog/"
    >
      <Image
        sx={{ width: '2rem', height: '2rem', mr: 3 }}
        src={ArrowLeftDark}
        alt=""
        aria-hidden
      />
      Zpět do seznamu článků
    </Box>
  )
}

export default BlogBack
