import React, { useState } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { Box, Button, Text } from 'rebass/styled-components'
import { Label, Input } from '@rebass/forms/styled-components'

import getValidationSchema from './newsletter-form-validation'
import TextInput from './text-input'

const validationSchema = getValidationSchema()

const InputGroup = ({ children, label }) => {
  return (
    <Box sx={{ display: 'grid', gap: 2, alignContent: 'start' }}>
      <Label sx={{ mb: 1, fontWeight: 'bold', color: 'white' }}>{label}</Label>

      {children}
    </Box>
  )
}

const InputRow = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridAutoFlow: ['row', 'row', 'column'],
        gridTemplateColumns: ['1fr', '1fr', '1fr'],
        gap: 4,
        width: '100%',
      }}
    >
      {children}
    </Box>
  )
}

const NewsletterForm = () => {
  const [submission, setSubmission] = useState({
    success: false,
    error: false,
    sending: false,
  })

  const sendMail = (values, { resetForm }) => {
    setSubmission({
      ...submission,
      sending: true,
      success: false,
    })

    setTimeout(() => {
      setSubmission({
        sending: false,
        error: false,
        success: true,
      })
      resetForm()

      setTimeout(() => {
        setSubmission({
          ...submission,
          success: false,
        })
      }, 4000)
    }, 6000)

    fetch('/.netlify/functions/subscribe', {
      method: 'POST',
      body: JSON.stringify({
        email: values.email,
        phone: values.phone,
      }),
    })
      .then((response) => {
        if (response.ok) {
          setSubmission({
            sending: false,
            error: false,
            success: true,
          })
          resetForm()
        }
      })
      .catch((error) => {
        setSubmission({
          error,
          success: false,
          sending: false,
        })
      })
  }

  return (
    <Formik
      initialValues={{
        email: '',
        phone: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values, formik) => {
        sendMail(values, formik)
      }}
    >
      {() => (
        <Form>
          <Box
            sx={{
              display: 'grid',
              gridAutoFlow: 'row',
              gridTemplateColumns: ['1fr', '1fr 1fr 1fr'],
              columnGap: [3, 4],
              rowGap: [2, 3],
              alignItems: 'center',
              mb: [4, 3],
            }}
          >
            <InputRow>
              <InputGroup label="E-mail">
                <Input
                  as={TextInput}
                  id="email"
                  name="email"
                  type="email"
                  placeholder="jan.novak@email.cz"
                />
              </InputGroup>
            </InputRow>

            <InputRow>
              <InputGroup label="Telefon">
                <Input
                  as={TextInput}
                  id="phone"
                  name="phone"
                  type="tel"
                  placeholder="+420 000 000 000"
                />
              </InputGroup>
            </InputRow>

            <Button
              type="submit"
              variant="secondary"
              disabled={submission.sending}
              sx={{
                mt: 1,
                p: 3,
                fontSize: 2,
                fontWeight: 'bold',
                lineHeight: 1.75,
              }}
            >
              {submission.sending ? `Odesílám...` : `Zjistit více informací`}
            </Button>
          </Box>

          <Text
            variant="body"
            sx={{ color: 'white', fontSize: 1, lineHeight: '1.25' }}
          >
            Odesláním formuláře souhlasíte se&nbsp;
            <Link to="/gdpr">zpracováním&nbsp;osobních&nbsp;údajů</Link>
            .
          </Text>

          {submission.success && (
            <Text sx={{ mt: 4, color: 'white' }}>
              Vaše informace byly úspěšně odelsány.
            </Text>
          )}
        </Form>
      )}
    </Formik>
  )
}

InputGroup.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
}

InputRow.propTypes = {
  children: PropTypes.node.isRequired,
}

export default NewsletterForm
