import { createGlobalStyle } from 'styled-components'

import { rangeInputStyles } from './inputRangeStyles'
import slickCarouselStyles from './slickCarouselStyles'

const GlobalStyle = createGlobalStyle`
  html, body, div, p, a {
    font-variant-numeric: lining-nums;
  }

  html {
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
  }

  body {
    font-family: ${(props) => props.theme.fonts.body};
  }

  p {
    line-height: 1.75;
  }

  ul {
    margin-bottom: 1rem;
  }

  strong {
    font-weight: 600;
  }

  ol {
    list-style: decimal;
    margin-bottom: 2rem;
    padding-left: 1.5rem;
  }

  ol > li {
  }

  ol > li > ul {
    list-style: disc;
    padding-left: 1rem;
  }

  em {
    font-style: italic;
  }

  ${rangeInputStyles}
  ${slickCarouselStyles({
    primary: (props) => props.theme.colors.primary,
    secondary: (props) => props.theme.colors.secondary,
  })}
`

export default GlobalStyle
