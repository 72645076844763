import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { Box, Card, Link as RebassLink, Text } from 'rebass/styled-components'

import { getImages } from '../utils/getImages'
import ReferenceImage from './reference-image'

const ReferenceList = ({ references, referenceImages }) => {
  const { defaultHousePhoto, defaultAptPhoto } = useStaticQuery(graphql`
    {
      defaultHousePhoto: file(relativePath: { eq: "default-house-photo.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      defaultAptPhoto: file(relativePath: { eq: "default-apt-photo.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: ['1fr'],
        gap: 3,
      }}
    >
      {references.map((reference) => {
        const { hide, title, type, imagePath } = reference
        const images = getImages({
          referenceImages: reference.images,
          imagePath,
          images: referenceImages,
        })

        let firstImage = null

        // Hide reference with hide parameter === true
        if (hide) {
          return null
        }

        // Hide references with no photo
        if (images.length === 0) {
          return null
        }

        if (images.length > 0) {
          firstImage = images[0]
        } else {
          firstImage = {
            after: {
              node: type === 'apartment' ? defaultAptPhoto : defaultHousePhoto,
            },
          }
        }

        return (
          <RebassLink
            key={imagePath}
            as={Link}
            to={`/reference/${imagePath}`}
            sx={{
              cursor: 'pointer',
              color: 'primary',
              textDecoration: 'none',
              transition: 'all 200ms ease-out',
              '&:hover': { color: 'secondary' },
            }}
          >
            <Card
              sx={{
                p: 3,
                boxShadow: 'none',
                transition: 'all 300ms ease-out',
                '&:hover': { boxShadow: '0 4px 24px rgba(0, 0, 0, 0.1)' },
              }}
            >
              <Box sx={{ mb: 3 }}>
                <ReferenceImage image={firstImage} title={title} />
              </Box>

              <Text sx={{ fontSize: 3 }}>{title}</Text>
            </Card>
          </RebassLink>
        )
      })}
    </Box>
  )
}

ReferenceList.propTypes = {
  references: PropTypes.array.isRequired,
  referenceImages: PropTypes.array.isRequired,
}

export default ReferenceList
