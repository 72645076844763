import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Box, Heading } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/section'
import Container from '../components/container'
import BlogPostDetail from '../components/blog-post-detail'
import BlogBack from '../components/blog-back'
import { BlogPostsListItem } from '../components/blog-posts-list'

const BlogPostTemplate = ({ data }) => {
  const { blogPost, otherBlogPosts } = data
  const { frontmatter, body } = blogPost
  const { title, date, slug } = frontmatter
  const blogPostData = {
    title,
    date,
    slug,
    body,
  }
  const now = new Date().getTime()
  let readMore = null

  if (otherBlogPosts && otherBlogPosts.edges.length > 0) {
    otherBlogPosts.edges.forEach(({ node: post }) => {
      const { dateOriginal } = post.frontmatter
      const publishDate = new Date(dateOriginal).getTime()

      if (now >= publishDate && readMore === null) {
        readMore = {
          ...post.frontmatter,
        }
      }
    })
  }

  return (
    <Layout hideMenuToggle showSecondaryLogo>
      <SEO title={title} description={title} />

      <Section sx={{ pt: 6 }}>
        <Container width="40rem">
          <BlogBack />

          {blogPost && (
            <Box sx={{ mb: 4 }}>
              <BlogPostDetail blogPost={blogPostData} readMore={readMore} />
            </Box>
          )}

          {readMore && (
            <Box sx={{ my: 4, p: 4, bg: 'secondary' }}>
              <Heading as="h2" sx={{ mb: 3 }}>
                Mohlo by Vás zajímat
              </Heading>

              <BlogPostsListItem {...readMore} horizontal />
            </Box>
          )}

          <BlogBack />
        </Container>
      </Section>
    </Layout>
  )
}

BlogPostTemplate.propTypes = {
  data: PropTypes.shape({
    blogPost: PropTypes.shape({
      frontmatter: PropTypes.shape({
        date: PropTypes.string.isRequired,
        featuredImage: PropTypes.object,
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }),
      body: PropTypes.string.isRequired,
    }),
    otherBlogPosts: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query blogPostTemplateQuery($slug: String!) {
    blogPost: mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        date(formatString: "D.M.YYYY")
        slug
        title
      }
    }
    otherBlogPosts: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/blog/" }
        frontmatter: { slug: { ne: $slug } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            date(formatString: "D.M.YYYY")
            dateOriginal: date
            slug
            title
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`
