/* eslint-disable import/prefer-default-export */
/**
 * Inspired by https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/
 */

import { css } from 'styled-components'

const rangeThumbStyles = css`
  border: 2px solid ${(props) => props.theme.colors.rangeSliderThumbBorder};
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 2rem;
  background: ${(props) => props.theme.colors.secondary};
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -12px;
  box-shadow: 0px 4px 12px rgba(0, 3, 81, 0.1);
  transition: all 200ms ease-out;
`

const rangeTrackStyles = css`
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: ${(props) => props.theme.colors.lineColor};
  border-radius: 2px;
  border: none;
`

export const rangeInputStyles = css`
  input[type='range'] {
    -webkit-appearance: none;
    margin: 18px 0;
    width: 100%;
  }
  input[type='range']:focus {
    outline: none;
  }
  input[type='range']::-webkit-slider-runnable-track {
    ${rangeTrackStyles}
  }
  input[type='range']::-webkit-slider-thumb {
    ${rangeThumbStyles}
  }
  input[type='range']::-webkit-slider-thumb:hover {
    background-color: ${props => props.theme.colors.lightSecondary};
  }
  input[type='range']:focus::-webkit-slider-runnable-track {
    background: ${(props) => props.theme.colors.primary};
  }
  input[type='range']::-moz-range-track {
    ${rangeTrackStyles}
  }
  input[type='range']::-moz-range-thumb {
    ${rangeThumbStyles}
  }
  input[type='range']::-ms-track {
    ${rangeTrackStyles}
  }
  input[type='range']::-ms-fill-lower {
    background: #2a6495;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  input[type='range']::-ms-fill-upper {
    background: #3071a9;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  input[type='range']::-ms-thumb {
    ${rangeThumbStyles}
  }
  input[type='range']:focus::-ms-fill-lower {
    background: #3071a9;
  }
  input[type='range']:focus::-ms-fill-upper {
    background: ${(props) => props.theme.colors.primary};
  }
`
