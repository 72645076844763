import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, Heading, Text } from 'rebass/styled-components'
import { BgImage } from 'gbimage-bridge'

import Section from '../section'
import Container from '../container'
import NewsletterForm from '../newsletter-form'

const StyledBackgroundImage = ({ children, ...rest }) => (
  <Box
    sx={{
      p: 4,
      backgroundColor: 'primary',
      backgroundPosition: '120% 20%',
      backgroundSize: 'auto 200%',
      '&:after, &:before': { mixBlendMode: 'multiply', opacity: 0.3 },
    }}
    as={BgImage}
    {...rest}
  >
    {children}
  </Box>
)

const Newsletter = () => {
  const { brozura } = useStaticQuery(graphql`
    {
      brozura: file(relativePath: { eq: "brozura-iso.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Section
      sx={{ py: 0, background: 'linear-gradient(to bottom, #FFF5EB, #fff)' }}
    >
      <Container>
        <StyledBackgroundImage
          image={brozura.childImageSharp.gatsbyImageData}
          Tag="div"
        >
          <Box
            sx={{
              display: 'grid',
              gap: 3,
            }}
          >
            <Heading
              as="h2"
              variant="title"
              sx={{ color: 'white', fontSize: [5, 5, 6] }}
            >
              Zajímá Vás více informací o&nbsp;dluhopisu
              Acrebond&nbsp;02&nbsp;/&nbsp;7,0&nbsp;%?
            </Heading>

            <Text variant="body" sx={{ mb: 3, color: 'white' }}>
              Vyplňte prosím Vaše kontaktní údaje a my Vám zašleme náš infolist
              a budeme Vás kontaktovat s bližšími informacemi.
            </Text>

            <NewsletterForm />
          </Box>
        </StyledBackgroundImage>
      </Container>
    </Section>
  )
}

StyledBackgroundImage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Newsletter
