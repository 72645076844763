import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Box, Heading } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/section'
import Container from '../components/container'
import BlogPostsList from '../components/blog-posts-list'

const BlogPostsPage = ({ data }) => {
  const { blogPosts } = data

  return (
    <Layout hideMenuToggle showSecondaryLogo>
      <SEO title="Blog" description="Blog firmy Acrebond a.s." />

      <Section
        sx={{
          pt: 6,
          position: 'relative',
          bg: 'lightSecondary',
          '&:after': {
            content: '""',
            width: '100%',
            height: '100%',
            position: 'absolute',
            clipPath: 'polygon(100% 0, 100% 45%, 66% 65%, 33% 70%, 0 80%, 0 0)',
            bg: 'white',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        }}
      >
        <Box sx={{ position: 'relative', zIndex: 1 }}>
          <Container>
            <Heading as="h1" sx={{ mb: 4, color: 'primary', fontSize: 8 }}>
              Blog
            </Heading>

            <BlogPostsList blogPosts={blogPosts.edges} />
          </Container>
        </Box>
      </Section>
    </Layout>
  )
}

BlogPostsPage.propTypes = {
  data: PropTypes.shape({
    blogPosts: PropTypes.shape({
      edges: PropTypes.array,
    }).isRequired,
  }),
}

export default BlogPostsPage

export const pageQuery = graphql`
  {
    blogPosts: allMdx(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            date(formatString: "D.M.YYYY")
            dateOriginal: date
            slug
            title
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`
