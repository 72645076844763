import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Heading, Text } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/section'
import Container from '../components/container'
import ContactSection from '../components/sections/contact'

const BuybackPage = ({ data }) => (
  <Layout hideMenuToggle showSecondaryLogo>
    <SEO
      title={data.buyback.frontmatter.title}
      description={data.buyback.frontmatter.title}
    />

    <Section sx={{ pt: 6 }}>
      <Container>
        <Heading as="h1" sx={{ mb: 3, color: 'primary', fontSize: 8 }}>
          {data.buyback.frontmatter.title}
        </Heading>

        <Text
          variant="body"
          dangerouslySetInnerHTML={{ __html: data.buyback.html }}
        />
      </Container>
    </Section>

    <ContactSection hideAddress />
  </Layout>
)

BuybackPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BuybackPage

export const pageQuery = graphql`
  {
    buyback: markdownRemark(
      frontmatter: { slug: { eq: "buyback" } }
    ) {
      frontmatter {
        title
        description
      }
      html
    }
  }
`
