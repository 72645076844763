export const trackConversion = (value = 0) => {
  if (typeof window !== 'undefined') {
    window.gtag('event', 'conversion', {
      send_to: 'AW-451753300/xIxYCNbTuu8BENTqtNcB',
      value: value || 0,
      currency: 'CZK',
      transaction_id: '',
    })
  }
}
