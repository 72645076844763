import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Heading, Text, Box, Card, Link } from 'rebass/styled-components'
import { interpolateNumber } from 'd3-interpolate'

import Section from '../section'
import Container from '../container'
import Calculator from '../calculator'
import formatCurrency from '../../utils/formatCurrency'
import { trackConversion } from '../../utils/trackConversion'

const Parameter = ({ title, value }) => {
  return (
    <Box sx={{}}>
      <Text variant="body">{title}</Text>

      <Text variant="body" sx={{ fontSize: 4, fontWeight: 700 }}>
        {value}
      </Text>
    </Box>
  )
}

const CalculatorSection = ({ onRangeChange }) => {
  const [gainValue, setGainValue] = useState(0)
  const [range, setRange] = useState(0)

  const handleValueChange = (values) => {
    setGainValue(values.gainTotal)
    setRange(values.range)
    onRangeChange(values.range)
  }

  const i = interpolateNumber(0, -50)
  const graphCoef = i(range / 5000000)
  const graphY = (y) => y + graphCoef
  const clipPath = `
    polygon(
      0 100%,
      0 ${graphY(90)}%,
      25% ${graphY(75)}%,
      50% ${graphY(70)}%,
      75% ${graphY(77)}%,
      100% ${graphY(60)}%, 
      100% 100%
    )`

  return (
    <Section
      sx={{
        position: 'relative',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          transition: 'clip-path 75ms',
          bg: '#feebd2',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 0,
        }}
        style={{
          clipPath,
        }}
      />

      <Box sx={{ position: 'relative', zIndex: 1 }}>
        <Container>
          <Box
            sx={{
              display: 'grid',
              gap: 4,
              mb: 3,
            }}
          >
            <Heading as="h2" variant="sectionTitle">
              Spočítejte si výnos Vaší investice
            </Heading>
            <Box
              sx={{
                display: 'grid',
                gridAutoFlow: ['row', 'row', 'column'],
                gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
                gap: 5,
              }}
            >
              <Box sx={{ display: 'grid', gap: 3 }}>
                <Calculator step={25000} onValueChange={handleValueChange} />

                <Box
                  sx={{
                    display: 'grid',
                    gap: 2,
                    gridAutoFlow: 'column',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                  }}
                >
                  <Parameter title="Úroková sazba" value="7,0 % p.a." />
                  <Parameter title="Délka investice" value="5 let" />
                  <Parameter title="Výplata úroků" value="pololetní" />
                </Box>
              </Box>

              <Box>
                <Card variant="cardTopBorder">
                  <Text variant="body" sx={{ mb: 2, fontSize: 5 }}>
                    Výnos při držbě do splatnosti
                  </Text>

                  <Text sx={{ mb: 3, fontSize: 9, fontWeight: 700 }}>
                    {formatCurrency(gainValue)}
                  </Text>

                  <Text as="p">
                    <Link
                      variant="linkButtonPrimary"
                      href="https://dluhopisy.acrebond.cz"
                      target="_blank"
                      sx={{ mb: 3 }}
                      onClick={() => trackConversion(range)}
                    >
                      Chci investovat
                    </Link>
                  </Text>

                  <Text variant="body" sx={{ fontSize: 1 }}>
                    Jedná se o výnosnost před zdaněním.
                    <br />
                    U fyzických osob je tato daň 15 %.
                  </Text>
                </Card>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Section>
  )
}

Parameter.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

CalculatorSection.propTypes = {
  onRangeChange: PropTypes.func.isRequired,
}

export default CalculatorSection
