import React from 'react'
import { graphql } from 'gatsby'
import { Box, Heading, Text } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Container from '../components/container'

const GDPRPage = ({ data }) => (
  <Layout hideMenuToggle showSecondaryLogo>
    <SEO
      title="Nakládání s osobními údaji"
      description="Nakládání s osobními údaji"
    />

    <Box sx={{ py: 6 }}>
      <Container>
        <Heading as="h1" sx={{ mb: 5, color: 'primary', fontSize: 6 }}>
          Nakládání s osobními údaji
        </Heading>

        <Text
          variant="body"
          dangerouslySetInnerHTML={{ __html: data.gdpr.html }}
        />
      </Container>
    </Box>
  </Layout>
)

export default GDPRPage

export const pageQuery = graphql`
  {
    gdpr: markdownRemark(frontmatter: { slug: { eq: "gdpr" } }) {
      frontmatter {
        title
      }
      html
    }
  }
`
