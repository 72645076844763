import React from 'react'
import PropTypes from 'prop-types'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Box, Heading, Text } from 'rebass/styled-components'

const BlogPostDetail = ({ blogPost }) => {
  if (!blogPost) {
    return null
  }

  const { title, date, body } = blogPost

  return (
    <Box>
      <Heading as="h1" sx={{ mb: 3, color: 'primary', fontSize: 8 }}>
        {title}
      </Heading>

      <Text as="p" sx={{ mb: 2, color: 'lineColor' }}>
        {date}
      </Text>

      <Text
        variant="body"
        sx={{
          '.table-responsive': {
            width: '100%',
            overflowX: 'auto',
          },
          table: { width: '100%', overflow: 'hidden', textAlign: 'left' },
          'table td, table th': { px: 3, fontSize: 1 },
          'table tr:first-child': { borderBottom: '1px solid #d0d0d0' },
        }}
      >
        <MDXRenderer>{body}</MDXRenderer>
      </Text>
    </Box>
  )
}

BlogPostDetail.propTypes = {
  blogPost: PropTypes.shape({
    title: PropTypes.string.isRequired,
    date: PropTypes.string,
    slug: PropTypes.string,
    body: PropTypes.string,
  }).isRequired,
}

export default BlogPostDetail
