import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Box, Heading, Text } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/section'
import Container from '../components/container'
import ReferenceList from '../components/reference-list'

const ReferencePage = ({ data }) => {
  const {
    references: { nodes: references },
  } = data
  const {
    referenceImages: { edges: referenceImages },
  } = data

  return (
    <Layout hideMenuToggle showSecondaryLogo>
      <SEO
        title="Příklady realizovaných investic"
        description="Příklady realizovaných investic firmy Acrebond a.s."
      />

      <Section sx={{ pt: 6 }}>
        <Container>
          <Heading as="h1" sx={{ mb: 4, color: 'primary', fontSize: 8 }}>
            Příklady realizovaných investic
          </Heading>

          <Box sx={{ mb: 4 }}>
            <Heading as="h2" sx={{ mb: 3 }}>
              Bohaté zkušenosti na realitním trhu
            </Heading>

            <Text as="p" variant="body">
              Naše skupina má za sebou celou řadu úspěšných obchodů
              s&nbsp;nemovitostmi. Díky dlouhodobým zkušenostem a znalosti
              realitního trhu jsme schopni nacházet zajímavé příležitosti, které
              přinášejí vysokou pravděpodobnost dvouciferného zhodnocení
              investice během krátkého období. Zaměřujeme se však i na
              &nbsp;dlouhodobější držbu nemovitostí spojenou s&nbsp;jejich
              pronájmem a&nbsp;možností následného prodeje.
            </Text>
          </Box>
        </Container>

        {/* <Container>
          <Box sx={{ mb: 4 }}>
            <Heading as="h2" sx={{ mb: 3, textAlign: 'center' }}>
              Příklady realizovaných investic
            </Heading>
          </Box>
        </Container> */}

        <Container width="40rem">
          <ReferenceList
            references={references}
            referenceImages={referenceImages}
          />
        </Container>
      </Section>
    </Layout>
  )
}

ReferencePage.propTypes = {
  data: PropTypes.shape({
    references: PropTypes.object.isRequired,
    referenceImages: PropTypes.object.isRequired,
  }),
}

export default ReferencePage

export const pageQuery = graphql`
  {
    references: allReferenceJson {
      nodes {
        id
        hide
        title
        type
        imagePath
        images {
          before
          after
        }
      }
    }
    referenceImages: allFile(
      filter: {
        internal: { mediaType: { eq: "image/jpeg" } }
        relativeDirectory: { regex: "/reference/" }
      }
    ) {
      edges {
        node {
          relativeDirectory
          relativePath
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`
