import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Box, Text } from 'rebass/styled-components'

import Container from './container'

const Footer = ({ siteTitle }) => (
  <Box variant="footer" as="footer">
    <Container>
      <Box
        sx={{
          display: 'grid',
          gap: 4,
          textAlign: ['left', 'left', 'center'],
        }}
      >
        <Text
          sx={{
            color: 'white',
            fontSize: 1,
            display: 'grid',
            gridAutoFlow: ['row', 'column'],
            gap: 3,
            mb: [3, 0, 0],
            justifyContent: ['start', 'center', 'center'],
          }}
        >
          <Link style={{ color: 'white' }} to="/o-spolecnosti">
            O společnosti
          </Link>
          <Link style={{ color: 'white' }} to="/pro-investory">
            Informace pro investory
          </Link>
          <Link style={{ color: 'white' }} to="/ochrana-soukromi">
            Ochrana soukromí
          </Link>
          <Link style={{ color: 'white' }} to="/gdpr">
            Nakládání s osobními údaji
          </Link>
          <Link style={{ color: 'white' }} to="/cookies">
            Cookies
          </Link>
        </Text>

        <Text sx={{ color: 'white', fontSize: 1 }}>
          {`${siteTitle} © ${new Date().getFullYear()}`}
        </Text>
      </Box>
    </Container>
  </Box>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: '',
}

export default Footer
