import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Box, Heading } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/section'
import Container from '../components/container'
import ReferenceDetail from '../components/reference-detail'
import ReferenceBack from '../components/reference-back'

const getReferenceImages = (reference, images) => {
  if (!reference) {
    return null
  }

  return images.filter(({ node: image }) => {
    if (image.relativeDirectory.match(`reference/${reference.imagePath}`)) {
      return image
    }
    return null
  })
}

const getReferenceFiles = (reference, files) => {
  if (!reference || !reference.files) {
    return null
  }

  const refFiles = []

  reference.files.forEach((refFile) => {
    const file = files.find((f) => f.node.relativePath === refFile.filename)

    refFiles.push({
      ...refFile,
      ...file.node,
    })
  })

  return refFiles
}

const ReferenceTemplate = ({ data }) => {
  const {
    reference,
    referenceImages: { edges: referenceImages },
    referenceFiles: { edges: referenceFiles },
  } = data
  const { title } = reference

  return (
    <Layout hideMenuToggle showSecondaryLogo>
      <SEO title={title} description={title} />

      <Section sx={{ pt: 6 }}>
        <Container>
          <ReferenceBack />

          <Heading as="h1" sx={{ mb: 3, color: 'primary', fontSize: 8 }}>
            {title}
          </Heading>

          {reference && (
            <Box sx={{ mb: 4 }}>
              <ReferenceDetail
                reference={reference}
                images={getReferenceImages(reference, referenceImages)}
                files={getReferenceFiles(reference, referenceFiles)}
              />
            </Box>
          )}

          <ReferenceBack />
        </Container>
      </Section>
    </Layout>
  )
}

ReferenceTemplate.propTypes = {
  data: PropTypes.shape({
    reference: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }),
    referenceImages: PropTypes.object.isRequired,
    referenceFiles: PropTypes.object.isRequired,
  }),
}

export default ReferenceTemplate

export const pageQuery = graphql`
  query referenceTemplateQuery($slug: String!) {
    reference: referenceJson(imagePath: { eq: $slug }) {
      id
      title
      type
      description
      files {
        title
        filename
      }
      imagePath
      images {
        before
        after
      }
      tables {
        rows {
          key
          value
          variant
        }
      }
    }
    referenceFiles: allFile(filter: { extension: { eq: "pdf" } }) {
      edges {
        node {
          publicURL
          relativePath
        }
      }
    }
    referenceImages: allFile(
      filter: {
        internal: { mediaType: { eq: "image/jpeg" } }
        relativeDirectory: { regex: "/reference/" }
      }
    ) {
      edges {
        node {
          relativeDirectory
          relativePath
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`
