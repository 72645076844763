import * as Yup from 'yup'

const getValidationSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .email('Zadejte prosím platný formát e-mailu.')
      .required('Napište prosím Vaši e-mailovou adresu.'),
    phone: Yup.string(),
  })
}

export default getValidationSchema
