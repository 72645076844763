import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Heading, Text } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/section'
import Container from '../components/container'
import Documents from '../components/documents'

const AboutPage = ({ data }) => (
  <Layout hideMenuToggle showSecondaryLogo>
    <SEO
      title={data.forInvestors.frontmatter.title}
      description="Informace pro investory Acrebond a.s."
    />

    <Section sx={{ pt: 6 }}>
      <Container>
        <Heading as="h1" sx={{ mb: 3, color: 'primary', fontSize: 8 }}>
          {data.forInvestors.frontmatter.title}
        </Heading>

        <Text
          variant="bodyText"
          dangerouslySetInnerHTML={{ __html: data.forInvestors.html }}
        />
      </Container>
    </Section>

    <Section sx={{ bg: 'lightSecondary' }}>
      <Container>
        <Documents />
      </Container>
    </Section>

    <Section>
      <Container>
        <Text variant="bodyText" sx={{ p: { mb: 3 }, fontSize: 1 }}>
          <p>
            Schválení prospektu ČNB by se nemělo chápat jako potvrzení
            dluhopisů, které jsou nabízeny nebo přijaty k obchodování na
            regulovaném trhu.
          </p>
          <p>
            Potenciálním investorům doporučujeme přečíst si prospekt, nežli
            učiní investiční rozhodnutí, aby tak plně pochopili potenciální
            rizika a výnosy spojené s rozhodnutím investovat do cenných papírů.
          </p>
        </Text>
      </Container>
    </Section>
  </Layout>
)

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const pageQuery = graphql`
  {
    companyAddress: markdownRemark(
      frontmatter: { slug: { eq: "companyAddress" } }
    ) {
      frontmatter {
        title
      }
      html
    }
    forInvestors: markdownRemark(
      frontmatter: { slug: { eq: "forInvestors" } }
    ) {
      frontmatter {
        title
      }
      html
    }
  }
`
