import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'rebass/styled-components'

const Container = ({ children, width = 960, px = 3, sx = {} }) => (
  <Box
    variant="container"
    sx={{
      maxWidth: width,
      px,
      ...sx,
    }}
  >
    {children}
  </Box>
)

Container.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
  ]),
  px: PropTypes.number,
  sx: PropTypes.object,
}

Container.defaultProps = {
  width: 960,
  px: 3,
  sx: {},
}

export default Container
