/* eslint-disable new-cap */
import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import rehypeReact from 'rehype-react'
import { Heading, Text, Box, Card } from 'rebass/styled-components'
import { BgImage } from 'gbimage-bridge'

import Section from '../section'
import Container from '../container'

const strong = ({ children }) => <strong>{children}</strong>

const Background = ({ image, children }) => (
  <Box
    as={BgImage}
    image={image}
    Tag="section"
    sx={{
      py: [4, 4, 5],
      px: [3, 3, 5, 6],
      mx: [-3, -3, -5, -6],
      position: 'relaitve',
      bg: 'secondary',
      backgroundPosition: 'center',
      // '&:after, &:before': { mixBlendMode: 'multiply', opacity: '0.2 !important' },
    }}
  >
    <Box
      sx={{
        position: 'relative',
        zIndex: 1,
        display: 'grid',
        gridAutoFlow: ['row', 'row', 'column'],
        gridTemplateColumns: '1fr, 1fr',
        gap: 4,
      }}
    >
      {children}
    </Box>
    <Box
      sx={{
        height: '95%',
        width: '1px',
        bg: 'white',
        position: 'absolute',
        bottom: '0',
        left: '80%',
      }}
    />
  </Box>
)

const UsageSection = () => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { slug: { eq: "usage" } }) {
        frontmatter {
          title
        }
        htmlAst
      }
      buildingWindow: file(relativePath: { eq: "building-window.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `)

  const { htmlAst } = data.markdownRemark
  const renderAst = new rehypeReact({
    createElement: React.createElement,
    Fragment: (props) => <>{props.children}</>,
    components: {
      p: ({ children }) => (
        <Text as="p" variant="body">
          {children}
        </Text>
      ),
      h3: ({ children }) => (
        <Heading as="h3" sx={{ mb: 3 }}>
          {children}
        </Heading>
      ),
      strong: ({ children }) => <strong>{children}</strong>,
      card: ({ children }) => (
        <Card
          variant="cardTopBorder"
          sx={{
            // bg: 'rgba(255, 255, 255, 0.95)',
            '& p': { mb: 3 },
            '& p:last-of-type': { mb: 0 },
          }}
        >
          {children}
        </Card>
      ),
      background: ({ children }) => (
        <Background image={data.buildingWindow.childImageSharp.gatsbyImageData}>
          {children}
        </Background>
      ),
    },
  }).Compiler

  return (
    <Section sx={{ bg: 'lightSecondary' }}>
      <Container>
        <Box
          sx={{
            display: 'grid',
            gap: 4,
          }}
        >
          <Heading as="h2" variant="sectionTitle">
            Propagační sdělení
          </Heading>

          <Text variant="body">
            Potenciálním investorům doporučujeme přečíst si{' '}
            <Link to="/pro-investory">prospekt</Link>, nežli učiní investiční
            rozhodnutí, aby tak plně pochopili potenciální rizika a výnosy
            spojené s rozhodnutím investovat do cenných papírů. Schválení
            prospektu by se nemělo chápat jako potvrzení cenných papírů.
          </Text>

          <Heading as="h2" variant="sectionTitle">
            {data.markdownRemark.frontmatter.title}
          </Heading>

          <Box
            sx={{
              display: 'grid',
              gap: 4,
            }}
          >
            {renderAst(htmlAst)}
          </Box>
        </Box>
      </Container>
    </Section>
  )
}

export default UsageSection
