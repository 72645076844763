const findImageByRelativePath = (images, relativePath) => {
  if (!images || images.length === 0) {
    return null
  }

  return images.find(({ node: image }) => image.relativePath === relativePath)
}

// Get all GatsbyImageSharp images from objects in every reference
export const getImages = ({ referenceImages, imagePath, images }) => {
  const newImages = referenceImages.map((refImage) => {
    const newImage = {}

    // Go through all keys in image object and assign relative path
    Object.keys(refImage).forEach((key) => {
      const fileName = refImage[key]
      const relativePath = `reference/${imagePath}/${key}/${fileName}`
      const image = findImageByRelativePath(images, relativePath)

      newImage[key] = image
    })

    return newImage
  })

  return newImages
}
