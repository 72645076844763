/* eslint-disable no-unused-expressions */
import React from 'react'
import PropTypes from 'prop-types'
import { Field, useField } from 'formik'
import styled from 'styled-components'

import ErrorMessage from './error-message'

const StyledField = styled(Field)`
  outline: none;
`

const Input = ({ ...props }) => {
  const [field, meta] = useField(props)

  return (
    <>
      <StyledField
        aria-required={props.required}
        {...field}
        {...props}
        id={props.id || props.name}
        haserror={meta.error && meta.touched ? true : undefined}
        autoComplete={props.autoComplete || undefined}
        onBlur={(e) => {
          field.onBlur(e)
          props.onBlur && props.onBlur(e)
        }}
      />

      <ErrorMessage>{meta.touched && meta.error ? meta.error : <>&nbsp;</>}</ErrorMessage>
    </>
  )
}

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  autoComplete: PropTypes.string,
  required: PropTypes.bool,
  onBlur: PropTypes.func,
  haserror: PropTypes.bool,
}

Input.defaultProps = {
  type: 'text',
  autoComplete: null,
  required: false,
}

export default Input
