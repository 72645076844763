const locale = 'cs-CZ'
const currencyFormat = {
  style: 'currency',
  currency: 'CZK',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}

const formatCurrency = (value) =>
  new Intl.NumberFormat(locale, currencyFormat).format(value)

export default formatCurrency
