import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import {
  Heading,
  Flex,
  Link as RebassLink,
  Box,
} from 'rebass/styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

import Section from '../section'
import Container from '../container'

const ReferencesSection = () => {
  const { referenceImages } = useStaticQuery(graphql`
    {
      referenceImages: allFile(
        filter: {
          internal: { mediaType: { eq: "image/jpeg" } }
          relativeDirectory: { regex: "/reference/homepage/" }
        }
        limit: 3
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.333)
          }
        }
      }
    }
  `)

  return (
    <Section sx={{ bg: '#feebd2' }}>
      <Container>
        <Heading as="h2" variant="sectionTitle" sx={{ mb: 4 }}>
          Příklady realizovaných investic
        </Heading>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: ['repeat(1, 1fr)', 'repeat(3, 1fr)'],
            gap: 3,
            mb: 4,
          }}
        >
          {referenceImages &&
            referenceImages.nodes &&
            referenceImages.nodes.map((image) => (
              <RebassLink
                key={image.childImageSharp.gatsbyImageData.src}
                as={Link}
                to="/reference"
                sx={{
                  p: 0,
                  display: 'block',
                  transition: 'all 200ms ease-out',
                  '&:hover': { boxShadow: '0 4px 24px rgba(0, 0, 0, 0.2)' },
                }}
              >
                <GatsbyImage
                  image={image.childImageSharp.gatsbyImageData}
                  alt=""
                />
              </RebassLink>
            ))}
        </Box>

        <Flex sx={{ justifyContent: 'center' }}>
          <RebassLink as={Link} variant="linkButtonPrimary" to="/reference">
            Více realizovaných investic
          </RebassLink>
        </Flex>
      </Container>
    </Section>
  )
}

export default ReferencesSection
