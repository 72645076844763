import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Card, Link as RebassLink, Text } from 'rebass/styled-components'

export const BlogPostsListItem = ({
  slug,
  featuredImage,
  title,
  date,
  horizontal = false,
}) => {
  return (
    <RebassLink
      key={slug}
      as={Link}
      to={`/blog/${slug}`}
      sx={{
        cursor: 'pointer',
        color: 'primary',
        textDecoration: 'none',
        transition: 'all 200ms ease-out',
        '&:hover': { color: 'secondary' },
      }}
    >
      <Card
        sx={{
          p: 0,
          boxShadow: 'none',
          borderTop: '4px solid',
          borderTopColor: 'secondary',
          transition: 'all 300ms ease-out',
          '&:hover': { boxShadow: '0 4px 24px rgba(0, 0, 0, 0.1)' },
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridAutoFlow: horizontal ? ['row', 'column'] : 'row',
            gridTemplateColumns: horizontal ? ['1fr', '1fr 1fr'] : '1fr',
          }}
        >
          {featuredImage &&
            featuredImage.childImageSharp &&
            featuredImage.childImageSharp.gatsbyImageData && (
              <Box>
                <GatsbyImage
                  image={featuredImage.childImageSharp.gatsbyImageData}
                  alt={title}
                />
              </Box>
            )}

          <Box sx={{ p: 3 }}>
            <Text sx={{ mb: 2, fontSize: 3, lineHeight: '1.5' }}>{title}</Text>

            <Text as="p" sx={{ mb: 0, color: 'lineColor' }}>
              {date}
            </Text>
          </Box>
        </Box>
      </Card>
    </RebassLink>
  )
}

const BlogPostsList = ({ blogPosts }) => {
  let gridTemplateColumns = ['1fr', '1fr', '1fr 1fr 1fr']
  switch (blogPosts.length) {
    case 1:
      gridTemplateColumns = ['1fr']
      break
    default:
      break
  }

  const now = new Date().getTime()

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns,
        gap: 3,
      }}
    >
      {blogPosts.map(({ node: blogPost }) => {
        const { frontmatter } = blogPost
        const { date, dateOriginal, featuredImage, slug, title } = frontmatter
        const publishDate = new Date(dateOriginal).getTime()

        if (now >= publishDate) {
          return (
            <BlogPostsListItem
              date={date}
              featuredImage={featuredImage}
              slug={slug}
              title={title}
            />
          )
        }

        return null
      })}
    </Box>
  )
}

BlogPostsList.propTypes = {
  blogPosts: PropTypes.array.isRequired,
}

BlogPostsListItem.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  featuredImage: PropTypes.object.isRequired,
  horizontal: PropTypes.bool,
}

BlogPostsListItem.defaultProps = {
  horizontal: false,
}

export default BlogPostsList
